import React from 'react'
import { Link } from 'react-router-dom'

const VenueComp = () => {
  return (
	<div className='text-white'>
			<div className='max-h-[600px] grid lg:py-48 md:p-20 px-8 py-32 justify-items-center rounded-lg border-2 border-gray-200 text-center bg-venue-bg bg-cover bg-black bg-opacity-40 bg-blend-darken'>

				<div className='mt-12 text-center text-2xl font-bold'>
					<h2>Omma</h2>
					<br/>
				</div>
				<div className='mt-12 text-center'>
					<h2 className='text-md'>Have a quick look at the directions below!</h2>
				</div>
				<div className='mt-4'>
					<Link to='https://maps.app.goo.gl/LA4vn8DM2UsUJ4Pp8' target='_blank'>
						<button
							class="btn  cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900" >
							GPS Directions
						</button>
					</Link>
				</div>
			</div>
		</div>
  )
}

export default VenueComp