import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MainPage from './pages/MainPage';
import Venue from './pages/Venue';
import Church from './pages/Church';
import Contact from './pages/Contact';

function App() {
  return (
	<Router>
		<div className='App'>
			<div className='content'>
				<Routes>
					<Route path='/' element={<MainPage/>}/>
					<Route path='/church' element={<Church/>}/>
					<Route path='/venue' element={<Venue/>}/>
					<Route path='/contact' element={<Contact/>}/>
				</Routes>
			</div>
		</div>
	</Router>
  );
}

export default App;

