import React from 'react'
import Container from '../components/Container'
import Header from '../components/Header'
import church from '../assets/church-img.jpg'
import CardWithBg from '../components/CardWithBg'

const Church = () => {
  return (
	<Container>
		<div className='mt-16 text-white'>
			<div className='grid !h-full lg:py-48 md:p-20 px-8 py-32 justify-items-center rounded-lg border-2 border-gray-200 text-center bg-church2 bg-cover bg-black bg-opacity-30 bg-blend-darken'>

				<div className='mt-12 text-center text-2xl font-bold'>
					<h2>Saturday, September 7th, 2024<br/>Tinos, Greece</h2>
				</div>
				<div className='mt-12 text-center'>
					<h2 className='text-md'>Have a quick look at the directions below!</h2>
				</div>
				<div className='mt-4'>
					<button
						class="btn-wide inline-block cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900" >
						GPS Directions
					</button>
				</div>
			</div>
		</div>
	</Container>
  )
}

export default Church