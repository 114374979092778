import Container from '../components/Container'
import React from 'react'

const Contact = () => {
  return (

	<Container>
		<div className='mt-8 grid gap-4 text-left'>
			<h2>The big day is fast approaching and we can’t wait to celebrate with our friends and family!</h2>
			<h2>We kindly wanted to ask you to RSVP by June 30th the latest and let us know if your children will also join our wedding. </h2>
			<h2>If you have any questions about the wedding or the RSVP process, feel free to reach out to us:</h2>				
		</div>
		<div className='grid gap-4 text-left mt-8'>
			<p>
			<span className='font-bold'>Anna Karatzi</span>
				<br/>
				+30 698 2250896
				<br/>
				annakaratzh@gmail.com
			</p>
			<p>
				<span className='font-bold'>George Androniadis</span>
				<br/>
				+30 697 6926177
				<br/>
				androniadisg@gmail.com
			</p>
		</div>
	</Container>
	
  )
}

export default Contact