import React, { useState } from 'react';
import invitation from '../assets/invitation.png'; // If you want to use it later.
import mainbg from '../assets/wedding-bg.jpg';
import bg2 from '../assets/wedding-bg.jpg';
import RsvpForm from './RsvpForm';
import Church from '../pages/Church';
import GiftModal from './GiftModal';

const Invitation = () => {
    const [isRsvpOpen, setIsRsvpOpen] = useState(false);
    const [isGiftOpen, setIsGiftOpen] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleRsvpSuccess = (message) => {
        setAlertMessage(message);
        setShowSuccessAlert(true);
        setTimeout(() => setShowSuccessAlert(false), 8000);
    };

    return (
        <>
            {showSuccessAlert && (
                <div className="fixed inset-x-0  bottom-6  flex justify-center px-4 animate-bounce-short">
				<div
				  className="relative p-4 rounded-lg bg-green-500 text-white shadow-md flex items-center justify-between w-full max-w-4xl"
				  role="alert"
				>
				  {/* Title centered and SVG icon removed (as per the updated requirement) */}
				  <span className="text-lg mx-auto">{alertMessage}</span>
		  
				  {/* Close button on the right */}
				  <button
					onClick={() => setShowSuccessAlert(false)} // Sets the visibility to false, effectively closing the alert
					className="absolute right-4 top-4 text-lg rounded-full p-1 leading-none focus:outline-none"
					style={{ margin: 0 }}  // Overriding any default margins
				  >
					&#10005; {/* Unicode "X" character */}
				  </button>
				</div>
			  </div>
            )}
            <div
                className="grid lg:py-24 md:p-16 px-8 py-16 justify-items-center rounded-lg border-2 border-gray-200 text-center tracking-widest"
                style={{
                    // backgroundImage: `url(${mainbg})`,
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'cover',
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    backgroundBlendMode: 'lighten',
                }}
            >
                <div>
                    <h2 className="text-lg font-medium italic">
                        It's a pleasure to invite you to our wedding
                    </h2>
                </div>
                <div className="mt-6 text-3xl font-bold">
                    <h2 className="mb-2">George</h2>
                    <h2 className="mb-2">&</h2>
                    <h2>Anna</h2>
                </div>
                <div className="mt-8 text-xl">
                    <h2>
                        SAT 07 SEPTEMBER 24
                        <br />
                        at 19:00
                    </h2>
                    <p className="mt-4">in</p>
                    <h2 className="text-2xl font-black mt-4">TINOS</h2>
                </div>
                <div className="mt-6">
                    <h2 className="text-lg font-medium italic">
                        We can't wait to celebrate with you!
                    </h2>
                </div>
                <div className="mt-6 grid sm:grid-cols-2 gap-2">
                    <button
                        className="btn-wide cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-sm font-semibold uppercase text-white transition duration-200 ease-in-out "
                        onClick={() => setIsRsvpOpen(true)}
                    >
                        RSVP
                    </button>
                    <button
                        className="btn btn-outline cursor-pointer rounded-md  px-4 py-3 text-sm font-semibold uppercase transition duration-200 ease-in-out"
                        onClick={() => setIsGiftOpen(true)}
                    >
                        Gift
                    </button>
                </div>
                {isRsvpOpen && (
                    <RsvpForm
                        onClose={() => setIsRsvpOpen(false)}
                        onSuccess={handleRsvpSuccess}
                    />
                )}
                {isGiftOpen && (
                    <GiftModal onClose={() => setIsGiftOpen(false)} />
                )}
            </div>
        </>
    );
};

export default Invitation;
