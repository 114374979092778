import React from 'react'
import Container from '../components/Container'
import Invitation from '../components/Invitation'
import ChurchComp from '../components/ChurchComp'
import VenueComp from '../components/VenueComp'
import Collapse from '../components/Collapse'

const MainPage = () => {
  return (
	<Container>
		<div className='mt-8'>
			<Invitation/>
		</div>
		<div className='mt-4 mb-8 grid md:grid-cols-2 gap-4'>
			<div>
				<ChurchComp></ChurchComp>
			</div>
			<div>
				<VenueComp></VenueComp>
			</div>

		</div>
	</Container>
	
  )
}

export default MainPage