import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div class="navbar bg-base-100">
      <div class="flex-1 !px-0">
		<Link to='/'>
        <a class="btn btn-ghost text-xl !px-0">G&A</a>
		</Link>
      </div>
      <div class="flex-none">
        <ul class="menu menu-horizontal px-1">
          {/* <li>
            <a>Location</a>
          </li>
		  <li>
            <a>Venue</a>
          </li> */}
		  <Link to='/contact'>
          <li>
              <h2>Contact</h2>
          </li>
		  </Link>
        </ul>
      </div>
    </div>
  );
};

export default Header;
