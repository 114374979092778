import React from 'react';

const GiftModal = ({ onClose }) => {
  // Prevent scrolling when the modal is open
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
  <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose}></div>
  <div className="fixed z-50 inset-0 overflow-y-auto">
    <div className="flex items-center justify-center min-h-screen">
	<div className="modal-box bg-white p-8 rounded-lg shadow-lg mx-4" role="dialog" aria-modal="true" aria-labelledby="modal-title">
        {/* Modal content */}
        {/* <h3 id="modal-title" className="text-lg leading-6 font-medium text-gray-900">Join us for an unforgettable day!</h3> */}
        <h2 className='mb-8'>Your presence is the greatest gift we could ask for, but if you wish to give something, we kindly ask to contribute to our future with a cash gift:</h2>
		<h2 className='font-bold mb-4'>Bank Account Details</h2>
		<p className='mb-2'>IBAN GR1902606300000060201467897</p>
		<p className='mb-2'>EUROBANK</p>
		<p className='mb-2'>Beneficiaries: Anna Karatzi-Georgios Androniadis</p>
		
        <button onClick={onClose} className="mt-4 !text-center">Close</button>
      </div>
    </div>
  </div>
</>

  );
};

export default GiftModal ;
