import React from 'react'
import Header from './Header'

const Container = ({ children }) => {
  return (
	<div className='h-screen 2xl:px-96 px-4'>
			<Header/>
			{children}
	</div>
  )
}

export default Container